import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';

@Component({
  selector: 'app-calendly',
  templateUrl: './calendly.component.html',
})
export class CalendlyComponent implements AfterViewInit, OnDestroy {
  private calendlyInstance: any;
  private static scriptLoaded = false;

  @Input({ required: true }) calendlyUrl: string;
  @Input() width: number = 320;
  @Input() height: number = 700;

  @ViewChild('calendlyContainer') calendlyContainer: ElementRef;

  ngAfterViewInit(): void {
    if (!this.calendlyUrl?.startsWith('https://calendly.com/')) {
      console.error('Invalid or missing Calendly URL');
      return;
    }
    
    const scriptPromise = CalendlyComponent.scriptLoaded
    ? Promise.resolve()
    : this.loadCalendlyScript();

    scriptPromise
    .then(() => {this.createCalendlyWidget()})
    .catch((error) => console.error('Failed to load Calendly script:', error));
  }

  loadCalendlyScript(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (CalendlyComponent.scriptLoaded) {
        resolve();
        return;
      }

      if (document.querySelector('script[src="https://assets.calendly.com/assets/external/widget.js"]')) {
        CalendlyComponent.scriptLoaded = true;
        resolve();
        return;
      }
      
      const script = document.createElement('script');
      script.src = 'https://assets.calendly.com/assets/external/widget.js';
      script.async = true;
      script.onload = () => {
        CalendlyComponent.scriptLoaded = true;
        resolve();
      };
      script.onerror = () => reject(new Error('Failed to load Calendly script'));
      document.body.appendChild(script);
    })
  }

  private createCalendlyWidget() {
    if (window.Calendly) {
      try {
        this.calendlyInstance = window.Calendly.initInlineWidget({
          url: this.calendlyUrl,
          parentElement: this.calendlyContainer.nativeElement,
          prefill: {},
          utm: {},
        });
      } catch (error) {
        console.error('Failed to initialize Calendly widget:', error);
      }
    }
  }

  ngOnDestroy(): void {
    if (this.calendlyInstance && typeof this.calendlyInstance.destroy === 'function') {
      this.calendlyInstance.destroy();
    }
    if (this.calendlyContainer?.nativeElement) {
      this.calendlyContainer.nativeElement.innerHTML = '';
    }
  }
}
