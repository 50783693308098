export const USERTYPES = {
  merchant: 'merchant',
  admin: 'admin',
  accountant: 'accountant',
};

export const validNamePattern = '^[A-Za-z.-]*$';

export const phoneNumberPattern = '^[\\d ()+-]+$';

export const averageOrders = Object.seal({
  200: 'Between 0 and 200',
  500: 'Between 201 and 500',
  1000: 'Between 501 and 1,000',
  5000: 'Between 1,001 and 5,000',
  10000: 'Between 5,001 and 10,000+',
});

export const TRANSACTIONS_CSV_HEADERS = [
  { name: 'provider', required: true },
  { name: 'transaction_id', required: true },
  { name: 'transaction_reference_id', required: false },
  { name: 'transaction_type', required: true },
  { name: 'transaction_date', required: true },
  { name: 'to_street', required: true },
  { name: 'to_city', required: true },
  { name: 'to_state', required: true },
  { name: 'to_zip', required: true },
  { name: 'to_country', required: true },
  { name: 'from_street', required: true },
  { name: 'from_city', required: true },
  { name: 'from_state', required: true },
  { name: 'from_zip', required: true },
  { name: 'from_country', required: true },
  { name: 'total_shipping', required: true },
  { name: 'total_handling', required: true },
  { name: 'total_sales_tax', required: true },
  { name: 'item_product_identifier', required: true },
  { name: 'item_description', required: true },
  { name: 'item_quantity', required: true },
  { name: 'item_unit_price', required: true },
  { name: 'item_shipping', required: true },
  { name: 'item_handling', required: true },
  { name: 'item_discount', required: true },
  { name: 'item_sales_tax', required: true },
  { name: 'item_product_tax_code', required: true },
  { name: 'exemption_type', required: true },
  { name: 'amount', required: true }
];

export enum PAYMENT_SCHEDULES {
  ANNUALLY = 'ANNUALLY',
  QUARTERLY = 'QUARTERLY',
  MONTHLY = 'MONTHLY',
  'SEMI-MONTHLY' = 'SEMI-MONTHLY',
  'FISCAL-ANNUALLY' = 'FISCAL-ANNUALLY',
  'SEMI-ANNUALLY' = 'SEMI-ANNUALLY',
  'QUARTERLY-PREPAY' = 'QUARTERLY-PREPAY',
  'BI-MONTHLY' = 'BI-MONTHLY',
}

export const DEFAULT_DATE_FORMAT = 'MM/DD/YYYY';

export const PICKER_DATE_FORMAT = DEFAULT_DATE_FORMAT.replaceAll('D', 'd');

export const UNIT_RATES = Object.freeze({
  CENTS: 100,
});

export enum CURRENCY_UNIT {
  DOLLAR = 'dollar',
  CENT = 'cent'
}

export enum FILE_SIZE {
  BYTE = 1,
  KILOBYTE = 1024,
  MEGABYTE = 1048576,
  GIGABYTE = 1073741824,
  TERABYTE = 1099511627776
}

export enum STORE_KEYS {
  TRANSACTION_PROVIDERS = 'atomickit:transaction-providers',
  SALES_TAX_CONFIG = 'atomickit:sales-tax-config',
  SALES_TAX_METADATA = 'atomickit:sales-tax-metadata',
  USER_AUTH_KEY = 'atomickit:user-auth-token',
  BUSINESS_PROFILE_KEY = 'atomickit:business-profile-data',
  BUSINESS_PROFILES_KEY = 'atomickit:business-profiles-data',
  SALES_REPORT = 'atomickit:sales-report',
  STATE_WITH_NEXUS = 'atomickit:state-with-nexus',
  AUTOREGISTER_STATES = 'atomictax:autoregister-states',
}

export enum STORE_TEMP_KEYS {
  AUTO_REGISTER_DETAIL,
  AUTO_FILING_DETAIL,
}

export enum EXEMPTIONS {
  GOVERNMENT = 'Government',
  WHOLESALE = 'Wholesale',
  OTHER = 'Other',
  MARKETPLACE = 'Marketplace',
  NON_EXEMPT = 'Non Exempt',
}

export enum TransactionProviders {
  SHOPIFY = 'shopify',
  STRIPE = 'stripe'
}
