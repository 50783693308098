import { NgbDate, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgxCsvParser } from 'ngx-csv-parser';
import { inject, Injectable } from '@angular/core';
import moment, { Moment } from 'moment';
import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable, of } from 'rxjs';
import _ from 'lodash';

import { TRANSACTIONS_CSV_HEADERS } from './constants';
import { Data } from '@angular/router';


export class MissingHeadersError extends Error {
  code = 'E_MISSING_HEADERS';
  missingHeaders: string[];

  constructor(missingHeaders: string[]) {
    super('CSV file does not contain valid headers');
    this.missingHeaders = missingHeaders;
  }
}

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  pageInfo: BehaviorSubject<Data> = new BehaviorSubject(Object.create(null))

  private readonly ngxCsvParser: NgxCsvParser = inject(NgxCsvParser)
  private readonly NgbDateFormatter: NgbDateParserFormatter = inject(NgbDateParserFormatter)

  get pageInformation(){
    return this.pageInfo.asObservable()
  }

  get transactionsCsvHeaders(): any[] {
    return TRANSACTIONS_CSV_HEADERS;
  }

  constructor() {}

  validateTransactionsCSVFile(
    file: File,
    checkForMissingHeaders = true,
    header = false,
    delimiter = ',',
  ): Observable<{ file: any; data: any }> {
    return this.ngxCsvParser.parse(file, { header, delimiter })
      .pipe(
        map((parsedResult: Array<any>) => {
          if (parsedResult.length === 0 || parsedResult.length === 1) {
            throw new Error('File does not contain transactions');
          }
          
          if(checkForMissingHeaders) {
            const transactionHeaders = this.transactionsCsvHeaders.map(header => header.name);
            const missingHeaders = _.difference(transactionHeaders, parsedResult[0]);
            
            if (missingHeaders.length) {
              throw new MissingHeadersError(missingHeaders);
            }
          }

          return { file, data: parsedResult };
        })
      );
  }

  unparseTransactionsToCSV(
    data: any[],
    filename: string = 'transactions.csv',
    header: boolean = true,
    delimiter: string = ','
  ): Observable<File> {
    if (!data || data.length === 0) {
      throw new Error('No data provided to convert to CSV');
    }
  
    if (header && (!this.transactionsCsvHeaders || this.transactionsCsvHeaders.length === 0)) {
      throw new Error('Headers configuration is missing');
    }
  
    const convertToCsv = (arr: any[]): string => {
      const headers = header 
        ? this.transactionsCsvHeaders.map(h => h.name)
        : Object.keys(arr[0]);
  
      const escapeCsvValue = (value: any): string => {
        if (value === null || value === undefined) return '';
        const str = value.toString();
        if (str.includes(delimiter) || str.includes('"') || str.includes('\n')) {
          return `"${str.replace(/"/g, '""')}"`;
        }
        return str;
      };
  
      const rows = arr.map(row =>  headers.map(header => escapeCsvValue(row[header])).join(delimiter));
  
      return header 
        ? [headers.join(delimiter), ...rows].join('\n')
        : rows.join('\n');
    };
  
    const csvContent = convertToCsv(data);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    
    // Create a File object from the Blob
    const file = new File([blob], filename, {
      type: 'text/csv',
      lastModified: Date.now()
    });
  
    return of(file);
  }

  validateFileSize(limitInByte: number, file: File): boolean {
    return file.size <= limitInByte;
  }

  NgbDateToMoment(date: NgbDate): Moment {
    return moment(this.NgbDateFormatter.format(date));
  }

  MomentToNgbDate(date: Moment): NgbDate {
    if (!date) {
      throw new Error('Please Put in a valid date');
    }
    return NgbDate.from({ year: date.year(), month: date.month() + 1, day: date.date() }); // moment months are zero indexed
  }
}
